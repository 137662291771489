.bgclr{
    background-color: blue;
}

.filter-col{
    height: 83vh;
    width: 25%;
    margin: 1rem;
}
.graph-col{
    height: 83vh;
    width: 75%;
    margin: 1rem;
}

@media only screen and (max-width: 640px) {

    .filter-col{
    height: auto;
    width: 100%;
    margin: 0;
    margin-top: 1rem;
     }
     .graph-col{
         height: 83vh;
         width: 100%;
        margin: 0;
        margin-top: 1rem;
     }

}
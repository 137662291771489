.collapse-wrapper {
  background-color: white;
  // min-height: 95vh;
  min-height: 100%;
}

@media only screen and (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 1230px !important;
}}

.layout-container {
  padding: 1rem 5rem;
  background-color: rgb(241, 241, 241);
  @media only screen and (max-width: 640px) {
    padding: 1rem;
  }

  .col-filter .col-chart {
    height: 700px;
  }
  .filter-header {
    height: 45px;
    color: white;
    padding: 10px 15px;
  }
}
.filter-radio {
  label {
    padding-left: 10px;
  }
}
.col-chart {
  background-color: white;
  padding: 20px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.chart-dropdown1 {
  // padding: 10px;
  // border: 1px solid black;
  // display: inline;
  margin-right: 5px;
}
.chart-header {
  // padding: 20px;
}
.chart-dropdown2 {
  // margin-left: 5px;
  // padding: 10px;
  // border: 1px solid black;
  // display: inline;
}
.btn-chart-1 {
  width: 2.5rem;
  height: 2.5rem;
  background: white;
  border: #0e5d72 1px solid;
  border-width: 2px;
  border-right: 0;
  color: white;
  background-color: #0e5d72;
}
.btn-chart-2 {
  width: 2.5rem;
  height: 2.5rem;
  background: white;
  border: #0e5d72 1px solid;
  border-width: 2px;
}

.btn-download {
  height: 2rem;
  background-color: #ffc947;
  border: 0;
  width: auto;
  span {
    position: relative;
    bottom: 3px;
  }
}
.anticon .anticon-right .ant-collapse-arrow {
  float: right !important;
}

.col-wrapper-blank {
  img {
    height: 25rem;
  }
}

.img-wrapper {
  // padding-left: 14rem;
  // padding-top: 3rem;
}

.side-item {
  margin: 0 !important;
  label {
    margin: 0;
  }
}
.chart-container {
  height: 60vh;
}

.week-web {
  display: block;

  .chart-dropdown1 {
    a:hover{
      text-decoration: none;
    }
  }

  .chart-dropdown2 {
    a:hover{
      text-decoration: none;
    }
  }
  

}
.week-mob {
  display: none;
}
.chart-header{
  a{
    text-decoration: none;
  }
}
// .ant-menu-submenu > .ant-menu {
//     z-index: 2147483647;
//     position: absolute;
//     background-color: white !important;
//   }
//   .ant-menu-inline {
//     width: 91%;
// }
// .ant-menu-submenu > .ant-menu {
//   background-color: #f0f5f7  !important;
// }

.total-market-trends{
  margin: 3px 0px;
  padding: 0.5rem 1rem !important;

  p{
    font-size: 14px;
    cursor: pointer;
  }
}

.ant-dropdown-menu-submenu-title:hover {
   background-color: #0e5d72 !important; 
   color:white
}
.collapse-wrapper{

  .ant-collapse{
    background-color: white !important;
    border: 0 !important;
    padding: 0 1rem !important;
  }
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 0 !important;
}




@media only screen and (max-width: 640px) {

  .antd-roww{
    display: inherit !important;
  }
  .collapse-wrapper {
    min-height: 0;
  }
  .chart-dropdown1 {
    margin-right: 0;
    margin: 5px 0;
  }
  .col-chart {
    min-height: 70vh;
    background-color: whitesmoke;
  }
  .col-2-wrapper {
    background-color: white;
  }
  .date-header {
    h3 {
      font-size: 1.38rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
  .chart-btn-div {
    float: right;
    margin-top: 35px;
  }
  .col-wrapper-blank{
    background-color: white;
    padding: 123px 3px;
  img {
    height: 8rem;
  }
} 
  .chart-container {
    height: 20rem;
    // height: 100%;
  }
  .week-web {
    display: none;
  }
  .week-mob {
    display: block;
  }

}

.ant-tabs-tab-active{
    background-color: #FFC947 !important;
}

.chart-collapse{
    // border: 2px solid red !important;
}

.card-grid{
    // height: 9rem;
    @media only screen and (max-width: 640px) {
    //    height: 15rem !important;
      }
}

.panel-header{
    background-color: #0E5D72;
    color: white;
    width: 100%;
}

.card-grid >.ant-card-grid  {
    background-color: white;
    float: left;
    width: 15% !important;
    padding: 10px;
    margin: 5px;
    border-radius: 0;
    // box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    box-shadow: 0px 0px 12px -7px rgba(0,0,0,0.75);

    transition: all 0.3s;

    @media only screen and (max-width: 640px) {
        width: 45% !important;
        padding: 10px;
        margin: 2px;
      }
}
.shadow-div{
    box-shadow: 0px 0px 12px -7px rgba(0,0,0,0.75);
}

.main-collapse-div{
    background-color: #fbfbfb;
    // padding: 3rem;
}

.bar-chart-div{
    background-color: white;
    margin: 2rem 0;
    padding: 2rem;
}
.bar-chart{
    height: 23rem;
}

.middle-chart{
    background-color: white;
    width: 48%;
    padding: 2rem;

    @media only screen and (max-width: 640px) {
        width: 100% ;
        
      }
}
.chart{
    height: 27rem;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    /* text-shadow: 0 0 0.25px currentColor; */
}
.ant-tabs-tab{
    padding: 9px 32px;

}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
}
.chart-collapse .ant-collapse-header {
    border: 1.5px solid #0E5D72 !important;
}
 .ant-collapse-content-active{
    background-color: #fbfbfb !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #0E5D72;
    border-color: #0E5D72;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #0E5D72;
    border-color: #0E5D72;
}

//COLLAPSE HEADER

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
     padding: 0 !important;
     line-height: 3;
}

.ant-picker-input>input::placeholder {
    color: BLACK;
    font-weight: 600;
    text-align: center;
 }

 .ant-picker-input .ant-picker-input-active{
     text-align: center !important;
 }

 .example {
    //  position: relative;
     top: 70%;
     left: 45%;
    margin: 20px 0;
    margin-bottom: 20px;
    height: 100vh;
    padding: 30px 50px;
    text-align: center;
    // background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .spin{
      position: relative;
      top: 25%;
  }